import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Rm } from '../models/registromujeres';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { CamisasPorcentajeCant } from '../models/camisas_restantes';


moment.locale('en');

@Injectable({
  providedIn: 'root'
})

export class RegistroMujeresService {
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';


  constructor(private http: HttpClient) { }

  getEncuestas(): Observable<Rm[]> {
    return this.http.get<any>(`${environment.url_api}getRM`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.rm)).map(item => {
        return new Rm(
          item.id_registro,
          item.folio,
          item.correo,
          item.nombre,
          item.apellidoP,
          item.apellidoM,
          item.edad,
          item.sexo,
          item.calle,
          item.numero,
          item.colonia,
          item.municipio,
          item.telefono,
          item.instructora,
          item.gimnasio,
          item.talla,
          item.createdAt,
        );
      });
    })
    );
  }


  getCamisasEntregadas(): Observable<CamisasPorcentajeCant[]> {
    return this.http.get<any>(`${environment.url_api}getPlayerasEntregadas`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.resultados)).map(item => {
        return new CamisasPorcentajeCant(
          item.talla,
          item.cantidad_entregada,
          parseFloat(item.porcentaje_entregado)
        );
      });
    })
    );
  }








  public exportExcel(jsonData: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

}