export const adminLteConf = {
    sidebarLeftMenu: [
        {
            label: 'Menú de Navegación',
            separator: true
        },
        {
            label: ' Inicio', 
            route: '/',
            iconClasses: 'fa fa-home ', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ]
        },
        { 
            label: ' Usuarios', 
            route: '/usuarios', 
            iconClasses: 'fa fa-user-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Registros', 
            route: '/registros', 
            iconClasses: 'fa fa-list', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Usuarios App', 
            route: '/usuariosA', 
            iconClasses: 'fa fa-mobile', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Analíticas', 
            route: '/analiticas', 
            iconClasses: 'fa fa-pie-chart', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        
        { 
            label: 'Asistencias', 
            route: '/asistencias', 
            iconClasses: 'fa fa-calendar', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        
        
    

    ]
};