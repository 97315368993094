export class Rm{
    constructor(
        public id_registro: string,
        public folio: string,
        public correo: string,
        public nombre: string,
        public apellidoP: string,
        public apellidoM: string,
        public edad: string,
        public sexo: string,
        public calle: string,
        public numero: string,
        public colonia: string,
        public municipio: string,
        public telefono: string,
        public instructora: string,
        public gimnasio: string,
        public talla: string,
        public createdAt: string
    ){}
}

